import axios from 'axios';
import qs from 'qs';
import utils from '@common/utils';


// CancelToken能为一次请求‘打标识’
// isCancel用于判断请求是不是被CancelToken取消的
const { CancelToken, isCancel } = axios;

// 测试环境
// const sturl='http://saas.test.shangtiiot.cn:8086/';
// 正式环境
const sturl='https://saas.shangtiiot.cn:8088/';
// 请求队列，缓存发出的请求
// const cacheRequest = {};


// console.log(',',process.env.VUE_APP_BASE_URL);
const instance = axios.create({
  // 测试环境
  baseURL: sturl+'st_sys_vm/',
  // 正式环境
  // baseURL: 'https://saas.shangtiiot.cn:8088/st_sys_vm/',
  // baseURL: 'http://localhost:15004/',
  // withCredentials:true,
  timeout: 60000,
  //这告诉服务器请求正文将发送为JSON格式。
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  transformRequest: [function (data, headers) {
    
  if (headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        // 把一个参数对象格式化为一个字符串
        return qs.stringify(data);
      } else if (headers['Content-Type'] === 'multipart/form-data;charset=UTF-8') {
        return data;
      } else {
        headers['Content-Type'] = 'application/json';
      }
      return JSON.stringify(data);
  return data;
  }],
  
    // transformRequest: [function (data) {
    // data = qs.stringify(data);
    // return data;
    // }]
  

});
// 请求拦截器
instance.interceptors.request.use(config =>{
    // config.withCredentials=true;
    // console.log(config);
    // console.log(utils.getLocal('token'));
    // console.log(sessionStorage['token']);
    if(sessionStorage['token']!=undefined){
      config.headers.Authorization='Bearer '+utils.getLocal('token');
      // token过期处理
      var date = new Date().getTime();
      var time=date+18000000;
      utils.saveLocal('tokentime', time);
      // console.log(utils.getLocal('tokentime'));
    }else{
      
    }
    
    if(config.requestBase=='EA'){
          //处方接口域名
          // 正式
          // config.baseURL='https://saas.shangtiiot.cn:8088/st_sys_exrx/';
          // 测试
      config.baseURL=sturl+'st_sys_exrx/';
         // config.baseURL='http://47.111.228.193:800/exrx/';
           // config.baseURL='http://localhost:15002/';
        }
    
    // if(utils.getLocal('token')!='null'){
    //   config.headers.Authorization='Bearer '+utils.getLocal('token');
    // }else{
      
    // }
    
    if (config.url=='/customer/update_customer_face'||config.url=='finance/import_order_data') {
      // console.log('Content-Type修改');
      // console.log(config);
      config.headers['Content-Type']='multipart/form-data;charset=UTF-8';
    }
    
    if (config.url=='finance/export_order_data'||config.url=='finance/export_check_data'||config.url=='daily/export_member_report'||config.url=='finance/export_super_data'||config.url=='cancel/export_cancel_data'||config.url=='customer/export_customer_list'||config.url=='daily/export_reservation_class_record'||config.url=='customer/export_customer_details') {
      // config.headers['Content-Type']='multipart/form-data;charset=UTF-8';
      // console.log(config);
      config.responseType='blob';
    }
    
    // console.log('修改' + config.url);
    if (config.url=='qingke/get_device_properties_set' || config.url=='qingke/get_scene_exec') {
       // console.log('Content-Type修改');
      config.headers['Content-Type']='application/json';
    }
    
    
    if (config.url=='logout/mylogout') {
      // config.withCredentials=true;
      // config.headers['Content-Type'] = 'multipart/form-data;charset=UTF-8';
      config.baseURL=sturl;
      // config.baseURL='https://saas.shangtiiot.cn:8088/';
    }
    
        // const { url, method } = config;
        // // 请求地址和请求方式组成唯一标识，将这个标识作为取消函数的key，保存到请求队列中
        // const reqKey = `${url}&${method}`;
        // // console.log('axios',url,reqKey);
        
        // // 如果存在重复请求，删除之前的请求
        // removeCacheRequest(reqKey);
        // // 将请求加入请求队列
        // config.cancelToken = new CancelToken(c => {
        //   cacheRequest[reqKey] = c;
        // });
        
        

    
    

    return config;
});

// 响应拦截器
instance.interceptors.response.use(
  response => {
    // 请求成功，从队列中移除
    // const { url, method } = response.config;
    // removeCacheRequest(`${url}&${method}`);
    
    // console.log(response);
    // window.location = '/login?type=1';
    if (response.config.url=='finance/export_order_data'||response.config.url=='finance/export_check_data'||response.config.url=='daily/export_member_report'||response.config.url=='finance/export_super_data'||response.config.url=='cancel/export_cancel_data'||response.config.url=='customer/export_customer_list'||response.config.url=='venue/export_reservation_data'||response.config.url=='venue/place_reservation_detail_execle'||response.config.url=='daily/export_reservation_class_record'||response.config.url=='customer/export_customer_details') {
    
      // console.log(1111);
      return response;
    }
    
    
    
    return response.data;
  },
  error => {
    if (error.response) {
      const status = error.response.status;
      
              // 请求失败，使用isCancel来区分是被CancelToken取消，还是常规的请求失败
              // if (isCancel(error)) {
              //   // 通过CancelToken取消的请求不做任何处理
              //   return Promise.reject({
              //     message: '重复请求，自动拦截并取消'
              //   });
              // } else{
              //     // 正常请求发生错误,抛出异常等统一提示
              //     console.log(error.response, 'errMsg');
              // }

      
      if (status === 401) {
        // 处理未授权错误
        console.log('Unauthenticated error');
        window.location = '/login';
        return error.response;
      } else if (status === 404) {
        // 处理找不到资源错误
        console.log('Resource not found error');
      } else {
        // 处理其他错误
        console.log('Other error');
      }
      // 跳转到错误页面
      // window.location.href = '/error.html';
    }
    return Promise.reject(error);
  }
);

function removeCacheRequest(reqKey) {
    console.log(reqKey);
    // if(reqKey=='venue/update_venue_attribute&post'||reqKey=='config/retrieve_config_status&post'||reqKey=='venue/retrieve_venue_list&post'){
    
    //   return;
    // }else{
    
    //   if (cacheRequest[reqKey]) {
    //     // 这里调用的就是上面的CancelToken生成的c函数，调用就会取消请求
    //     cacheRequest[reqKey]();
    //     // console.log('出现重复接口调用情况');
    //     delete cacheRequest[reqKey];
    //   }
    // }
    
    
    
    
    
  
}


//把 instance这个方法暴露出去
export default instance;
